import React from "react";
import { Helmet } from 'react-helmet';
import oklah from "../Images/oklah_office.jpg";
import Navbar from "../Componets/navbar";
import web from "../Images/web.jpg";
import Team from '../Componets/team';
const About = () => {
  return (
    <div>
      <Helmet>
        <title>Singapore best digital marketing agency | Oklah</title>
        <meta name="description" content="Oklah is a leading & affordable digital marketing agency in Singapore. Our services include SEO, PPC, social media, and more. Contact us today!" />
        <meta name="keywords" content="digital marketing, online marketing, SEO, social media, PPC, branding" />
      </Helmet>
      <Navbar />
      <img
                  src={oklah}
                  alt="..."
                  
                  width="100%"
                />
      <section className="news-details my-2">
        <div className="container p-5 ">
          <div className="row mb-2">
            <div className="col-12 col-md-6 d-flex align-items-center text-primary-emphasis">
              <div>
                
                <h1 className="fw-bold">
                Who we are

                </h1>
                
              </div>
            </div>
            
          </div>
          <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
          At OKLAH, we understand the unique challenges that small businesses face when it comes to establishing an online presence. That’s why we offer a range of affordable web development and digital marketing solutions that are tailored to meet the specific needs of small-scale businesses. We work closely with our clients to develop customized strategies that help them increase their visibility and attract more customers.


          </p>
          <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
          For larger-scale businesses, we offer a range of comprehensive solutions that can help them stay ahead of the competition. Our team of experts has experience working with businesses of all sizes and can help develop a strategy that aligns with your business goals and objectives.


          </p>
          <p
            style={{ textAlign: "justify" }}
            className="text-primary-emphasis fw-bold"
          >
            Mission Statement

          </p>
          <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
          We provide expert knowledge and experience in digital business to our clients with our team of passionate individuals committed to our values. Fuelled by ongoing learning and a holistic approach to business, our end-to-end social media solutions coupled with our hub of digital expertise, inspire and engage audiences to build long-term partnerships with our clients.


          </p>
          <div className="row mb-5">
            <div className="col-12 col-md-6">
              <img
              src={web}
              
                alt="..."
                className="img-fluid mt-3"
              />
            </div>
            <div className="col-12 col-md-6">
            <p
            style={{ textAlign: "justify" }}
            className="text-primary-emphasis fw-bold"
          >
            We Ask the Right Questions About Your Business


          </p>
              <p
                style={{ textAlign: "justify" }}
                className="text-primary-emphasis"
              >
                Before we start any project, we take the time to get to know your business. We ask the right questions to gain a deep understanding of your business, target audience, and competition.


              </p>
              <p
            style={{ textAlign: "justify" }}
            className="text-primary-emphasis fw-bold"
          >
           Developing Customized Solutions



          </p>
              <p
                style={{ textAlign: "justify" }}
                className="text-primary-emphasis"
              >
                Once we have a clear understanding of your business, we work alongside you to develop customized solutions that meet your specific needs. We deliver a solution that meets your expectations.


              </p>
              <p
            style={{ textAlign: "justify" }}
            className="text-primary-emphasis fw-bold"
          >
           Launching Your Solutions




          </p>
          <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
          We don’t just develop your solutions and leave you to it. We work alongside you to launch your solutions and provide ongoing support and maintenance to ensure that they continue to meet your needs over time.


          </p>
            </div>
          </div>
          <p
            style={{ textAlign: "justify" }}
            className="text-primary-emphasis fw-bold"
          ></p>
             <Team />
        </div>
        
      </section>
 
    </div>
  );
};

export default About;
