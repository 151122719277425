import React from "react";
import { Helmet } from 'react-helmet';
import oklah from "../Images/oklah_office.jpg";
import Navbar from "../Componets/navbar";
import oklahapps from "../Images/oklahapps.jpg";
import webdevelopment from "../Images/web-development-oklah.jpg";
const WebDev = () => {
    return (
      <div>
         <Helmet>
      <title>Website Development Services - OKLAH Digital Marketing Agency</title>
    <meta name="description" content="Explore our website development services at OKLAH Digital Marketing Agency. We offer customized solutions to build and enhance your online presence." />
    <meta name="keywords" content="website development, web development services, OKLAH Digital Marketing Agency, custom websites, responsive design" />
    <meta property="og:title" content="Website Development Services - OKLAH Digital Marketing Agency" />
      <meta property="og:description" content="Explore our website development services at OKLAH Digital Marketing Agency. We offer customized solutions to build and enhance your online presence." />
      <meta property="og:image" content="https://oklahsg.com/Images/web-development-oklah.jpg" />
      <meta property="og:url" content="https://oklahsg.com/website-development" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="OKLAH Digital Marketing Agency" />
  </Helmet>
        <Navbar />
        <img
                  src={oklah}
                  alt="..."
                  
                  width="100%"
                />
        <section className="news-details my-1">
          <div className="container p-1">
            <div className="row mb-5">
              <div className="col-12 col-md-6 d-flex align-items-center text-primary-emphasis">
                <div>
                  
                  
                  <h1 className="fw-bold">
                  Creative Solutions for Your Web Design Needs

                  </h1>
                 
                </div>
              </div>
              <div className="col-12 col-md-6">
                <img
                   src={oklahapps}
                  alt="..."
                  className="img-fluid mt-3"
                />
              </div>
            </div>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            At OKLAH Digital Marketing Agency, we specialize in crafting bespoke websites that not only look stunning but also deliver exceptional performance. Based in the heart of Singapore, our team of experienced designers and developers are dedicated to helping your business stand out in the digital landscape.
            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Our web design and development process is tailored to meet the unique needs of each client. We start by creating a detailed plan that outlines the scope of the project, the timeline, and the budget. Then, our talented designers will work to create a visually stunning website that is consistent with your brand identity and appeals to your target audience. Our developers will then bring the design to life, ensuring that the website is optimized for speed, security, and mobile responsiveness.            </p>            
            <h2 
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
             Our Services
            </h2>
            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
             Custom Website Design
            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
           <li> Tailored to your brand’s unique identity</li>
           <li>User-friendly interfaces that enhance user experience</li>
           <li>Mobile-responsive designs for optimal viewing on any device</li>
            </p>
            <div className="row mb-5">
              <div className="col-12 col-md-6">
                <img
                  src={oklahapps}
                  alt="..."
                  className="img-fluid mt-3"
                />
              </div>
              <div className="col-12 col-md-6">
              <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
             Website Development
            </p>
                <p
                  style={{ textAlign: "justify" }}
                  className="text-primary-emphasis"
                >
                  <li>Robust, scalable websites built on the latest technologies</li>
                  <li>SEO-friendly structures to boost your search engine rankings</li>
                  <li>E-commerce solutions to grow your online sales</li>
                </p>
                <p
                  style={{ textAlign: "justify" }}
                  className="text-primary-emphasis"
                >
                    <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
              Content Management Systems (CMS)
            </p>
            <li>Easy-to-use CMS platforms like React js</li>
            <li>Custom CMS solutions to meet your specific needs</li>
            <li>Training and support to empower you to manage your site</li>
                </p>             
                
              </div>
            </div>


            <div className="row mb-5">
              <div className="col-12 col-md-6">
              <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            ></p>
             <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
              Website Maintenance & Support

            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            <li>Regular updates and security patches</li>
            <li>Performance monitoring and optimization</li>
            <li>Technical support to keep your site running smoothly</li>
            </p>

            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
               UX/UI Design

            </p>
            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis "
            >
             <li> Intuitive user interfaces that engage and convert</li>
             <li>Data-driven design strategies to enhance usability</li>
             <li>Prototyping and user testing to ensure satisfaction</li>
            </p>
            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
              Web Applications


            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
           <li> Custom web applications tailored to your business processes</li>
           <li>Integration with existing systems and databases</li>
           <li>Secure and scalable solutions for future growth</li>
            </p>
              </div>
              <div className="col-12 col-md-6">
              <img
                  src={oklahapps}
                  alt="..."
                  className="img-fluid mt-3"
                />           
                
              </div>
              <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
              Why Choose OKLAH?



            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Expertise: Our team comprises skilled professionals with years of experience in web design and development.and your
              loved ones.
            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Innovation: We stay ahead of the curve with the latest trends and technologies.

            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Customization: We offer solutions that are tailored to your unique business needs.


            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Support: We provide ongoing support to ensure your website remains up-to-date and secure.
            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Results-Driven: Our goal is to deliver websites that drive results and contribute to your business growth.

            </p>
            <img
src={webdevelopment}
            
           
            className="img-fluid"
          />
            </div>  
            
            
            
          </div>
        </section>        
      </div>
    );
  };
  
  export default WebDev;
  