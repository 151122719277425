import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Container, Row, Col, Card } from 'react-bootstrap';
import logo1 from '../Images/client//L1.png';
import logo2 from '../Images/client/L2.png';
import logo3 from '../Images/client/L3.png';
import logo4 from '../Images/client/L4.png';
import logo5 from '../Images/client/L5.png';
import logo6 from '../Images/client/L6.png';
import logo7 from '../Images/client/L7.png';
import logo8 from '../Images/client/L8.png';
import logo9 from '../Images/client/L9.png';
import logo10 from '../Images/client/L10.png';
import logo11 from '../Images/client/L11.png';
import logo12 from '../Images/client/L12.png';
import logo13 from '../Images/client/L13.png';
import logo14 from '../Images/client/L14.png';
import logo15 from '../Images/client/L15.png';

const clients = [
  { name: 'Client 1', logo: logo1 },
  { name: 'Client 2', logo: logo2 },
  { name: 'Client 3', logo: logo3 },
  { name: 'Client 4', logo: logo4 },
  { name: 'Client 5', logo: logo5 },
  { name: 'Client 6', logo: logo6 },
  { name: 'Client 7', logo: logo7 },
  { name: 'Client 8', logo: logo8 },
  { name: 'Client 9', logo: logo9 },
  { name: 'Client 10', logo: logo10 },
  { name: 'Client 11', logo: logo11 },
  { name: 'Client 12', logo: logo12 },
  { name: 'Client 13', logo: logo13 },
  { name: 'Client 14', logo: logo14 },
  { name: 'Client 15', logo: logo15 },
 
  // Add more clients as needed
];

const ClientSection = () => {
  return (
    <Container className="my-5" >
    <div className=" pt-2">
      <Row>
      <Col>
        <h2>We are trusted by renowned brands</h2>
        <p>Driving their business growth in social media landscape.</p>
      </Col>
    </Row>
        </div>
    <div className="client-slider-container">      
      <Carousel indicators={false} controls={false} interval={100} className="client-slider">
        {clients.map((client, index) => (
          <Carousel.Item key={index}>
            <div className="d-flex justify-content-around align-items-center">
              {clients.slice(index, index + 7).map((clientSlice, sliceIndex) => (
                <div key={sliceIndex} className="client-logo">
                  <img
                    src={clientSlice.logo}
                    alt={clientSlice.name}
                    className="d-block"
                    style={{ maxHeight: '200px', objectFit: '' }}
                  />
                </div>
              ))}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
    </Container>
  );
};

export default ClientSection;
