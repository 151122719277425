import React, { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Navbar from "../Componets/navbar";
import { Link } from "react-router-dom";
import oklahoffice from "../Images/oklah_office.jpg";
import  trends1 from "../Images/trends_001.jpg"
import  trends2 from "../Images/trends_002.jpg"
import  trends3 from "../Images/trends_003.jpg"
const Socialmedia = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Navbar />
     
      <img
       src={oklahoffice}
        alt=""
        className="img-fluid"
      />
<section className="py-4 py-lg-5">
        <Container>
          <Row className="my-md-3 my-lg-0">
            <Col>
              <h3 className="text-center fw-bold mb-3">
              Insights
              </h3>
              <h6 className="text-center lh-base" style={{ fontSize: "14px" }}>
              We keep close to the latest digital marketing trends

              </h6>       
              
            </Col>
          </Row>
        </Container>
      </section>
      <Container fluid>
        <Row className="g-3 py-3">
          <Col md={6} lg={4}>
            <div className="p-3 bg-light">
              <Card className="rounded-0 border-0">
                <Card.Img
                  variant="top"
                  src={trends1}
                  className="bg-dark rounded-0"
                  height={"250px"}
                />
                <Card.Body className="p-0 py-3 bg-light">
                  <Card.Text className="mb-2"></Card.Text>
                  <Card.Title className="text-primary-emphasis">
                  Using Social Media Channels To Increase Restaurant Exposure
                  </Card.Title>
                  <Link to={`/using-social-media-channels-to-increase-restaurant-exposure`} variant=""><Button
                    variant=""
                    
                  >
                    Read Now <i class="fa-solid fa-angles-right"></i>
                  </Button></Link>

                  
                </Card.Body>
              </Card>
            </div>
          </Col>

          <Col md={6} lg={4}>
            <div className="p-3 bg-light">
              <Card className="rounded-0 border-0">
                <Card.Img
                  variant="top"
                  src={trends2}
                  className="bg-dark rounded-0"
                  height={"250px"}
                />
                <Card.Body className="p-0 py-3 bg-light">
                  <Card.Text className="mb-2"></Card.Text>
                  <Card.Title className="text-primary-emphasis">
                  Is Pay Per Click Strategy useful for digital marketing?
                  </Card.Title>
                  <Link to={`/is-pay-per-click-strategy-useful-for-digital-marketing`} variant=""><Button
                    variant=""
                    
                  >
                    Read Now <i class="fa-solid fa-angles-right"></i>
                  </Button></Link>
                </Card.Body>
              </Card>
            </div>
          </Col>

          <Col md={6} lg={4}>
            <div className="p-3 bg-light">
              <Card className="rounded-0 border-0">
                <Card.Img
                  variant="top"
                  src={trends3}
                  className="bg-dark rounded-0"
                  height={"250px"}
                />
                <Card.Body className="p-0 py-3 bg-light">
                  <Card.Text className="mb-2"></Card.Text>
                  <Card.Title className="text-primary-emphasis">
                    
How to create a successful B2B SEO strategy?
                  </Card.Title>
                  <Link to={`/how-to-create-a-successful-btwob-seo-strategy`} variant=""><Button
                    variant=""
                    
                  >
                    Read Now <i class="fa-solid fa-angles-right"></i>
                  </Button></Link>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Socialmedia;
