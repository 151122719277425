import React from "react";
import oklah from "../Images/oklah_office.jpg";
import Navbar from "../Componets/navbar";
import oklahapps from "../Images/oklahapps.jpg";

const PrivacyPolicy = () => {
  return (
    <div>
     <Navbar />
      
      <div className="container" style={{ textAlign: "justify" }}>
        <h5 className="text-primary-emphasis text-center fw-bold my-3">
        Privacy Policy

        </h5>
        <div>
          <h6 className="fw-bold bg-light p-2">
          Oklah Digital Marketing Agency is committed to protecting the privacy of our clients, visitors, and users. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you interact with us through our website and services.


          </h6>
          
          <h6 className="fw-bold bg-light p-2">
          1. Information We Collect
          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
          Personal Information: We may collect personal information that you provide to us, such as your name, email address, phone number, and any other information you choose to provide.

          </p>
          <p style={{ textAlign: "justify" }} className="p-2">
          Usage Data: We may collect information about how our website and services are accessed and used, including your IP address, browser type, pages visited, and time spent on the site.

          </p>
          <h6 className="fw-bold bg-light p-2">
          2. How We Use Your Information

          </h6>

          <p style={{ textAlign: "justify" }} className="p-2">
          Service Delivery: To provide and maintain our services, including responding to inquiries, processing transactions, and delivering digital marketing solutions.

          </p>

          <p style={{ textAlign: "justify" }} className="p-2">
          Communication: To communicate with you regarding our services, updates, and promotional materials.

          </p>
          <p style={{ textAlign: "justify" }} className="p-2">
          Improvement: To analyze and improve our website and services, ensuring a better user experience.

          </p>
          <p style={{ textAlign: "justify" }} className="p-2">
          Compliance: To comply with legal obligations and protect the rights and safety of Oklah Digital Marketing Agency and our users.

          </p>

          <h6 className="fw-bold bg-light p-2">
          3. Sharing Your Information
          </h6>

          <p style={{ textAlign: "justify" }} className="p-2">
          We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted third-party service providers who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.
          </p>
          <h6 className="fw-bold bg-light p-2">
          4. Cookies and Tracking Technologies
          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
          We use cookies and similar tracking technologies to monitor activity on our website and store certain information. Cookies help us enhance your user experience by remembering your preferences and enabling the functionality of our site.


          </p>
          <h6 className="fw-bold bg-light p-2">
          5. Data Security

          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
          We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure.


          </p>
          <h6 className="fw-bold bg-light p-2">
          6. Your Rights
          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
          You have the right to access, correct, or delete your personal information that we hold. You may also object to or restrict certain types of data processing. To exercise these rights, please contact us using the information provided below.


          </p>
          <h6 className="fw-bold bg-light p-2">
          7. Third-Party Links
          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
          Our website may contain links to third-party sites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review their privacy policies before providing any personal information.


          </p>
          <h6 className="fw-bold bg-light p-2">
          8. Changes to This Privacy Policy

          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
          Oklah Digital Marketing Agency may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. Please review this policy periodically for any updates.


          </p>
          <h6 className="fw-bold bg-light p-2">
          9. Contact Us

          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
          If you have any questions or concerns about this Privacy Policy, or if you wish to exercise your rights, please contact us at:


          </p>
          <h6 className="fw-bold bg-light p-2">
          Oklah Digital Marketing Agency

          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
          Email: hello@oklahsg.com
Phone: +(65) 91989719


          </p>
          
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
