import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './team.css';
import logo from '../Images/thiru.jpg';
import sudhan from '../Images/sudhan.jpg';

const teams = [
  
  { name: ' Ponnuthurai Sudhan', detp: ' Founder & CEO', logo: sudhan },
  { name: ' Thiruppathy Ganesan', detp: ' Head of Social Media', logo: logo },
  
  
  
  // Add more clients as needed
];

const Team = () => {
  return (
    <Container className="my-5">
      <div className=" pt-2">
        <Row>
        <Col>
          <h2>Our agency is fuelled and influenced by diverse cultures.</h2>
          <p>Our work involves both the digital and analog brain.</p>
        </Col>
      </Row>
          </div>
      <Row>
        {teams.map((Team, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4" >
            <Card className="text-center" >
              <Card.Img variant="top" src={Team.logo} alt={Team.name}  height={"250px"} />
              <Card.Body>
                <Card.Title className="text-primary-emphasis fw-bold" >{Team.name} </Card.Title>
                <Card.Title >{Team.detp} </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Team;
