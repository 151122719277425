
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import './navbar.css';
import logo from "../Images/logo.png";

const NavBar = () => {
  return (
    
    <Navbar bg="dark" expand="lg" className="navbar-custom" >
      <Container>
        <Navbar.Brand href="/" className="navbar-brand-custom">
          <img
            src={logo}
            alt="OKLAH"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/" className="nav-link-custom" style={{color:"white" }}>Home</Nav.Link>
            <Nav.Link as={Link} to="/about-us" className="nav-link-custom" style={{color:"white" }}>About Us</Nav.Link>
            <Nav.Link as={Link} to="/services" className="nav-link-custom" style={{color:"white" }}>Services</Nav.Link>
            <Nav.Link as={Link} to="/social-media-trends" className="nav-link-custom" style={{color:"white" }}>Insights</Nav.Link>
            <Nav.Link as={Link} to="/contact-us" className="nav-link-custom" style={{color:"white" }}>Contact Us</Nav.Link>            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>    
   
  );
};

export default NavBar;
