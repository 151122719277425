import React from "react";
import { Helmet } from 'react-helmet';
import Navbar from "../Componets/navbar";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import  trends1 from "../Images/trends_001.jpg"
import  web from "../Images/web.jpg"
import  app from "../Images/app.jpg"
import oklah from "../Images/oklah_office.jpg";
import Local_SEO from "../Images/oklah-Local_SEO.jpg";
import analytics from "../Images/analytics_reporting.jpg";
import content from "../Images/content-marketing.jpg";
import socialmedia from "../Images/social-media-marketing.jpg";
import payper from "../Images/pay-per-click.jpg";
import seo from "../Images/seo.jpg";


const Services = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (    
    <div style={{ backgroundColor: "#f4f4f4" }}>
      <Helmet>
      <title>Expert Digital Marketing Services | Oklah, Singapore</title>
    <meta name="description" content="Discover a wide range of digital marketing services from Oklah. We offer SEO, PPC, social media, and more to boost your online presence in Singapore." />
    <meta name="keywords" content="digital marketing, online marketing, SEO, social media, PPC, branding" />
  </Helmet>
          <Navbar />
          <img
                  src={oklah}
                  alt="..."
                  
                  width="100%"
                />
                <div>
        <Container className="py-4">
        <Row>
        <Col>
          <h2 className=" text-primary-emphasis fw-bold ">Our Services</h2>
        </Col>
      </Row>
          <p style={{ textAlign: "justify" }}>
          We offer a big picture in social media marketing that involves strategy planning, social media management, copywriting, video production, CRM, and social media analytics.


          </p>
        </Container>
      </div>
      <div>
        <Container fluid>
          <Row className="g-3 py-3">
          <Col xs={12} md={6} lg={6}>
              <Card className="rounded-0 border-0 p-lg-4">
                <Card.Body>
                  <Card.Title className="text-center  fw-bold ">
                  Web Development
                  </Card.Title>
                  <Card.Text className="text-center fs-18px">
                  Mobile application development is the process of creating software applications that run on a mobile device,
                  </Card.Text>
                  
                </Card.Body>
                <Link
                      to={`/website-development`}
                      className=" text-dark fw-bold"
                      onClick={handleClick}
                    >
                <Card.Img
                  variant="bottom"
                  src={web} style={{ height: "350px" }}
                />
                <Card.Text className="text-center fs-18px">
                  
                      Learn More{"  "}
                      <i class="fa-solid fa-angles-right"></i>
                    
                  </Card.Text>
                  </Link>
              </Card>
              
            </Col>

            <Col xs={12} md={6} lg={6}>
              <Card className="rounded-0 border-0 p-lg-4">
                <Card.Body>
                  <Card.Title className="text-center fw-bold ">
                  Apps Development
                  </Card.Title>
                  <Card.Text className="text-center fs-18px">
                  Mobile application development is the process of creating software applications that run on a mobile device,
                  </Card.Text>
                  
                </Card.Body>
                <Card.Img
                  variant="bottom"
                  src={app} style={{ height: "350px" }}
                />
                <Card.Text className="text-center fs-18px">
                  <Link
                      to={`/apps-development`}
                      className=" text-dark fw-bold"
                      onClick={handleClick}
                    >
                      Learn More{"  "}
                      <i class="fa-solid fa-angles-right"></i>
                    </Link>
                  </Card.Text>
              </Card>
              
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Card className="rounded-0 border-0 p-lg-4">
                <Card.Body>
                  <Card.Title className="text-center fw-bold ">
                  Search Engine Optimization (SEO)
                  </Card.Title>
                  <Card.Text className="text-center fs-18px">
                  SEO is the practice of optimizing a website to improve its visibility on search engines                  </Card.Text>
                  
                </Card.Body>
                <Card.Img
                  variant="bottom"
                  src={seo} style={{ height: "350px" }}
                />
                <Card.Text className="text-center fs-18px">
                  <Link
                      to={`/search-engine-optimization`}
                      className=" text-dark fw-bold"
                      onClick={handleClick}
                    >
                      Learn More{"  "}
                      <i class="fa-solid fa-angles-right"></i>
                    </Link>
                  </Card.Text>
              </Card>
              
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Card className="rounded-0 border-0 p-lg-4">
                <Card.Body>
                  <Card.Title className="text-center fw-bold ">
                  Pay Per Click (PPC)
                  </Card.Title>
                  <Card.Text className="text-center fs-18px">
                  Digital advertising model where advertisers pay a fee each time their ad is clicked, driving traffic to their websites                  </Card.Text>
                  
                </Card.Body>
                <Card.Img
                  variant="bottom"
                  src={payper} style={{ height: "350px" }}
                />
                <Card.Text className="text-center fs-18px">
                  <Link
                      to={`/pay-per-click-advertising-driving-traffic-to-your-website`}
                      className=" text-dark fw-bold"
                      onClick={handleClick}
                    >
                      Learn More{" "}
                      <i class="fa-solid fa-angles-right"></i>
                    </Link>
                  </Card.Text>
              </Card>
              
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Card className="rounded-0 border-0 p-lg-4">
                <Card.Body>
                  <Card.Title className="text-center fw-bold ">
                  Social media marketing (SMM)
                  </Card.Title>
                  <Card.Text className="text-center fs-18px">
                  SMM involves promoting products or services through social media platforms to engage with customers                  </Card.Text>
                  
                </Card.Body>
                <Card.Img
                  variant="bottom"
                  src={socialmedia} style={{ height: "350px" }}
                />
                <Card.Text className="text-center fs-18px">
                  <Link
                      to={`/search-engine-marketing`}
                      className=" text-dark fw-bold"
                      onClick={handleClick}
                    >
                      Learn More{"  "}
                      <i class="fa-solid fa-angles-right"></i>
                    </Link>
                  </Card.Text>
              </Card>
              
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Card className="rounded-0 border-0 p-lg-4">
                <Card.Body>
                  <Card.Title className="text-center fw-bold ">
                  Content Marketing
                  </Card.Title>
                  <Card.Text className="text-center fs-18px">
                  The goal is to drive profitable customer action by providing informative and engaging content
                  </Card.Text>
                  
                </Card.Body>
                <Card.Img
                  variant="bottom"
                  src={content} style={{ height: "350px" }}
                />
                <Card.Text className="text-center fs-18px">
                  <Link
                      to={`/content-marketing`}
                      className=" text-dark fw-bold"
                      onClick={handleClick}
                    >
                      Learn More{"  "}
                      <i class="fa-solid fa-angles-right"></i>
                    </Link>
                  </Card.Text>
              </Card>
              
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Card className="rounded-0 border-0 p-lg-4">
                <Card.Body>
                  <Card.Title className="text-center fw-bold ">
                  Analytics and Reporting
                  </Card.Title>
                  <Card.Text className="text-center fs-18px">
                  Analytics and reporting involve collecting, analyzing, and presenting data to help businesses make informed decisions.
                  </Card.Text>
                  
                </Card.Body>
                <Card.Img
                  variant="bottom"
                  src={analytics} style={{ height: "350px" }}
                />
                <Card.Text className="text-center fs-18px">
                  <Link
                      to={`/analytics-and-reporting`}
                      className=" text-dark fw-bold"
                      onClick={handleClick}
                    >
                      Learn More{"  "}
                      <i class="fa-solid fa-angles-right"></i>
                    </Link>
                  </Card.Text>
              </Card>
              
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Card className="rounded-0 border-0 p-lg-4">
                <Card.Body>
                  <Card.Title className="text-center fw-bold ">
                  Local SEO (GMB)
                  </Card.Title>
                  <Card.Text className="text-center fs-18px">
                  Local SEO focuses on optimizing a business’s online presence to attract local customers.

</Card.Text>
                  
                </Card.Body>
                <Card.Img
                  variant="bottom"
                  src={Local_SEO} style={{ height: "350px" }}
                />
                <Card.Text className="text-center fs-18px">
                  <Link
                      to={`/local-seo`}
                      className=" text-dark fw-bold"
                      onClick={handleClick}
                    >
                      Learn More{"  "}
                      <i class="fa-solid fa-angles-right"></i>
                    </Link>
                  </Card.Text>
              </Card>
              
            </Col>
            
          </Row>
        </Container>
      </div>

      
      </div>
  );
};

export default Services;
