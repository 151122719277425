import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Carousel, Col, Container, Row,Card,Button } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import "../App.css";
import heroimageone from "../Images/hero_001.jpg"
import  heroimagetwo from "../Images/hero_002.jpg"
import  heroimagethree from "../Images/hero_003.jpg"
import  web from "../Images/web.jpg"
import  app from "../Images/app.jpg"
import  hero from "../Images/hero_004.jpg"
import  trends1 from "../Images/trends_001.jpg"
import  trends2 from "../Images/trends_002.jpg"
import  trends3 from "../Images/trends_003.jpg"
import ClientSection from './ClientSection';
import  oklah from "../Images/oklah_office.jpg"
import Navbar from '../Componets/navbar';
import  ayg from "../Images/ayg.png";
  
function Home() {
  return (
    
    <>
 
      <div>
      <Helmet>
      <title>Singapore best digital marketing agency | Oklah</title>
        <meta name="description" content="Oklah is a leading & affordable digital marketing agency in Singapore. Our services include SEO, PPC, social media, and more. Contact us today!" />
        <meta name="keywords" content="digital marketing, online marketing, SEO, social media, PPC, branding" />
      </Helmet>
<Navbar/>


<section style={{ position: "relative" }}>
        
        <div style={{ position: "relative" }}>
          <img
src={hero}
            
            style={{
              height: "500px",
              width: "100%",
              objectFit: "cover",
            }}
            className="img-fluid"
          />
          <div
            style={{
              position: "absolute",
              left: "15%",
              bottom: "50px",
              transform: "translateX(-50%)",
              display: "flex",
              justifyContent: "center",
            }}
          >
           <Link to={`/contact-us`} variant=""> <button className="btn  text-center" style={{ marginRight: "10px" }}></button><Button
                  className="lh-base mt-3"
                  variant="outline-dark"
                  
                >
                  Contact Us 
                </Button></Link>
                  
          </div>
        </div>
      </section>
      <section className="container py-4" >
        <div className="text-center">
          <h4 className="fw-bold">What We Are</h4>
          <p className="">
          At OKLAH, we understand the unique challenges that small businesses face when it comes to establishing an online presence. That’s why we offer a range of affordable web development and digital marketing solutions that are tailored to meet the specific needs of small-scale businesses. We work closely with our clients to develop customized strategies that help them increase their visibility and attract more customers.
          </p>
          <p className="">For larger-scale businesses, we offer a range of comprehensive solutions that can help them stay ahead of the competition. Our team of experts has experience working with businesses of all sizes and can help develop a strategy that aligns with your business goals and objectives.

</p>
        </div>
      </section>
       <section>
        <Row className="g-0">
          <Col xs={12} md={6}>
            <div style={{ position: "relative" }}>
              <img
                src={web}
                alt=""
                style={{
                  height: "500px",
                  width: "100%",
                  objectFit: "cover",
                }}
                className="img-fluid"
              />
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  bottom: "30px",
                  transform: "translateX(-50%)",
                  display: "flex",
                  justifyContent: "center",
                  whiteSpace: "nowrap",
                }}
              >
               
<Link to={`/website-development`} variant=""><h1 className="me-3 fw-bolder text-white">Web Development<i class="fa-solid fa-angles-right"></i>
                  </h1></Link>
                
                
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div style={{ position: "relative" }}>
              <img
                src={app}
                alt=""
                style={{
                  height: "500px",
                  width: "100%",
                  objectFit: "cover",
                }}
                className="img-fluid"
              />
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  bottom: "30px",
                  transform: "translateX(-50%)",
                  display: "flex",
                  justifyContent: "center",
                  whiteSpace: "nowrap",
                }}
              >
                <Link to={`/apps-development`} variant=""><h1 className="me-3 fw-bolder text-white">Apps Development<i class="fa-solid fa-angles-right"></i>
                  </h1></Link>
                
                   
              </div>
              
            </div>
            
          </Col>
        </Row>
      </section>
      <Container id="services" className="my-5 ">
      <Row>
        <Col>
          <h2>OKLAH is a digital marketing agency in  Singapore.</h2>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>Search Engine Optimization (SEO)</Card.Title>
              <Card.Text>
              SEO is the practice of optimizing a website to improve its visibility on search engines, aiming to increase organic (non-paid) traffic. It involves keyword research, on-page optimization, and building high-quality backlinks.




              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>Pay Per Click (PPC)</Card.Title>
              <Card.Text>

              Digital advertising model where advertisers pay a fee each time their ad is clicked, driving traffic to their websites. This model is commonly used in search engine advertising, where businesses bid on keywords relevant to their target audience.







              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>Social media marketing (SMM)</Card.Title>
              <Card.Text>
              SMM involves promoting products or services through social media platforms to engage with customers, build brand awareness, and drive website traffic. It includes creating and sharing content, running ads, and engaging with followers.



              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>Content Marketing </Card.Title>
              <Card.Text>
              Content marketing focuses on creating and distributing valuable, relevant, and consistent content to attract and retain a clearly defined audience. The goal is to drive profitable customer action by providing informative and engaging content.



              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>Analytics and Reporting</Card.Title>
              <Card.Text>
              Analytics and reporting involve collecting, analyzing, and presenting data to help businesses make informed decisions. This process provides insights into performance metrics, customer behavior, and market trends to optimize strategies and operations.



              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>Local SEO (GMB)</Card.Title>
              <Card.Text>
              Local SEO focuses on optimizing a business’s online presence to attract local customers. Google My Business (GMB) is a key component, enhancing visibility in local search results by managing your business profile and ensuring accurate information.


</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>    
            
          <div className="text-center  py-2">
          <Link to={`/services`} variant=""> <button className="btn  text-center" style={{ marginRight: "10px" }}></button><Button
                  className="lh-base mt-3"
                  variant="outline-dark"
                  
                >
                  See Our Services 
                </Button></Link>
            
          </div>

          
    </Container>
      
      <section>
        <Container>
        <div className=" pt-2">
        <Row>
        <Col>
          <h2>We keep close to the latest digital marketing trends</h2>
          <p>To offer insights that enhance your marketing campaigns.</p>
        </Col>
      </Row>
          </div>
          <Row className="d-flex justify-content-center py-4">
            <Col md={4}>
            <Card.Img
                  variant="top"
                  src={trends1}
                  className="bg-dark rounded-0"
                  height={"250px"}
                />
              <Card className="">
            <Card.Body>
              <Card.Title>Using Social Media Channels To Increase Restaurant Exposure
</Card.Title>
              
            </Card.Body>
          </Card>
            </Col>
            <Col md={4}>
            <Card.Img
                  variant="top"
                  src={trends2}
                  className="bg-dark rounded-0"
                  height={"250px"}
                />
              <Card className="">
            <Card.Body>
              <Card.Title>Is Pay Per Click Strategy useful for digital marketing?

</Card.Title>
              
            </Card.Body>
          </Card>
            </Col>
            <Col md={4}>
            <Card.Img
                  variant="top"
                  src={trends3}
                  className="bg-dark rounded-0"
                  height={"250px"}
                />
              <Card className="">
            <Card.Body>
              <Card.Title>How to create a successful B2B SEO strategy?

</Card.Title>
              
            </Card.Body>
          </Card>
            </Col>
          </Row>
          <div className="text-center pt-2">        
            

            <Link to={`/social-media-trends`} variant=""> <button className="btn  text-center" style={{ marginRight: "10px" }}></button><Button
                  className="lh-base mt-3"
                  variant="outline-dark"
                  
                >
                   Read More 
                </Button></Link>
          </div>
        </Container>
      </section> 
    
      <section> 
      <Container className="py-5 text-center">
      <div className=" pt-2">
      <img               
                src={ayg} alt='OKLAH'
                
              />
              </div>
              <div className="text-center pt-4 py-3">        
            

            <Link to={`/services`} variant=""> <button className="btn  text-center" style={{ marginRight: "10px" }}></button><Button
                  className="lh-base mt-3" variant="outline-dark"                
                >
                  See All Our Projects  
                </Button></Link>
          </div>
              </Container>
              </section>
            
      <ClientSection />
      
      <Container className="py-2">
      <h2>A digital marketing agency based in Singapore</h2>
          <p>with the bustling city centres just a stone’s throw away.</p>

          </Container>
      <section>
      
        
        <div>
        
         
        
          <img
src={oklah}
            
           
            className="img-fluid"
          />
          <div
           
          >
            
            
          </div>
        </div>
        

          <div className="text-center  py-4">
          
            <Link to={`/about-us`} variant=""> <button className="btn  text-center" style={{ marginRight: "10px" }}></button><Button
                  className="lh-base mt-3" variant="outline-dark"                
                >
                  Meet the Team
                </Button></Link>
          </div>
      </section>


      </div>
      
      
      
      
      
      
    </>
  );
}

export default Home;
