import React from "react";
import { Helmet } from 'react-helmet';
import oklah from "../Images/oklah_office.jpg";
import Navbar from "../Componets/navbar";
import oklahapps from "../Images/oklahapps.jpg";
const AppsDev = () => {
    return (
      <div>
        <Helmet>
      <title>App Development Services - OKLAH Digital Marketing Agency</title>
      <meta name="description" content="Discover our app development services at OKLAH Digital Marketing Agency. We specialize in creating custom mobile and web applications tailored to your business needs." />
      <meta name="keywords" content="app development, mobile app development, web app development, OKLAH Digital Marketing Agency, custom applications" />
      <meta property="og:title" content="App Development Services - OKLAH Digital Marketing" />
      <meta property="og:description" content="Discover our app development services at OKLAH Digital Marketing Agency. We specialize in creating custom mobile and web applications tailored to your business needs." />
      <meta property="og:image" content="https://oklahsg.com/Images/oklahapps.jpg" />
      <meta property="og:url" content="https://oklahsg.com/apps-development" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="OKLAH Digital Marketing Agency" />
    </Helmet>
        <Navbar />
        <img
                  src={oklah}
                  alt="..."
                  
                  width="100%"
                />
        <section className="news-details my-1">
          <div className="container p-1">
            <div className="row mb-5">
              <div className="col-12 col-md-6 d-flex align-items-center text-primary-emphasis">
                <div>
                  
                  
                  <h1 className="fw-bold">
                  Empower Your Business with Custom Mobile Apps from OKLAH
                  </h1>
                 
                </div>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src={oklahapps}
                  alt="..."
                  className="img-fluid mt-3"
                />
              </div>
            </div>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            At OKLAH, Our team of experienced app developers is here to guide you through every step of the process, from brainstorming ideas to launching your app in the app store. We understand that creating an app can be a daunting task, but with our expertise, we can help make the process smoother and less stressful.         </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            We specialize in developing apps for both iOS and Android platforms. Our team is well-versed in the latest technology and trends, ensuring that your app will be up-to-date and user-friendly. We also offer custom app development, ensuring that your app is tailored to your specific needs and requirements.</p>            
<p style={{ textAlign: "justify" }} className="text-primary-emphasis">
But our service doesn’t stop there. We offer ongoing support and maintenance to ensure that your app is running smoothly and efficiently. We also offer marketing services to help promote your app and increase visibility in the app store.</p>          
            <h2 
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
             Our Services
            </h2>
            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
            Custom Mobile App Development
            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
           <li> Tailored solutions for iOS and Android platforms</li>
           <li>Native and cross-platform development to meet your needs</li>
           <li>High-performance apps with exceptional user experiences</li>
            </p>
            <div className="row mb-5">
              <div className="col-12 col-md-6">
              <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
            Enterprise App Development
            </p>
                <p
                  style={{ textAlign: "justify" }}
                  className="text-primary-emphasis"
                >
                  <li>Scalable and secure enterprise solutions</li>
                  <li>Integration with existing business systems</li>
                  <li>Streamlined workflows and process automation</li>
                </p>
                <p
                  style={{ textAlign: "justify" }}
                  className="text-primary-emphasis"
                >
                    <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
               E-commerce App Development
            </p>
            <li>User-friendly interfaces that enhance shopping experiences</li>
            <li>Secure payment gateways and transaction processing</li>
            <li>Features to boost customer engagement and loyalty</li>
                </p>           
                
              </div>
              <div className="col-12 col-md-6">              
              <img
                   src={oklahapps}
                  alt="..."
                  className="img-fluid mt-3"
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-6">
              <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            ></p>
             <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
              App UI/UX Design
            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            <li>Intuitive designs focused on user satisfaction</li>
            <li>Prototyping and user testing for optimal results</li>
            <li>Visual and interactive designs that captivate usersy</li>
            </p>
            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
               App Maintenance & Support
            </p>
            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis"
            >
             <li> Regular updates and bug fixes</li>
             <li>Performance monitoring and optimization</li>
             <li>Technical support to ensure smooth operation</li>
            </p>
            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
            Progressive Web Apps (PWA)
            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
           <li> Web-based apps with native app-like experiencess</li>
           <li>Offline capabilities and fast loading times</li>
           <li>Cross-browser compatibility and easy installation</li>
            </p>              
              </div>
              <div className="col-12 col-md-6">                     
              <img
                   src={oklahapps}
                  alt="..."
                  className="img-fluid mt-3"
                />                   
              </div>
              <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
              Why Choose OKLAH?
            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Expert Team: Our developers are skilled in the latest technologies and platforms.
            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Innovative Solutions: We focus on creating cutting-edge apps that stand out.
            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Customization: We tailor our solutions to meet your unique business requirements. 
                       </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Ongoing Support: We provide continuous support and updates to keep your app running smoothly.
            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Proven Results: Our apps are designed to deliver tangible business benefits.
            </p>
            </div>       
            
          </div>
        </section>
      </div>
    );
  };
  
  export default AppsDev;
  